import { Modal } from "ui/components/modals";

import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Shape } from "ui/components/shapes";
import { RxQuestionMark } from "ui/react-icons/rx";
import SupportFooter from "./SupportFooter";

import { setIsOpen } from "@/redux/slices/support/TicketSlice";
import Documentation from "./windows/home/Documentation";
import Legals from "./windows/home/Legals";
import Support from "./windows/home/Support";

export default function SupportModal() {
  const isOpen = useAppSelector((state) => state.support.tickets.isOpen);
  const dispatch = useAppDispatch();

  return (
    <Modal
      showModal={isOpen}
      setShowModal={(e) => dispatch(setIsOpen(e))}
      style={{ minHeight: "80vh" }}
      maxWidth={720}
      id="support-modal"
      className="p-0"
    >
      <div className="p-3">
        <div className="column j-center a-center mt-1">
          <Shape size={130} color="infoBackground">
            <RxQuestionMark size={70} color="var(--color-active)" />
          </Shape>

          <h2 className="text-center mt-3">Centre d&apos;informations</h2>
        </div>

        <div className="column j-center a-center" id="home">
          <Documentation />
          <Support />
          <Legals />
        </div>

        <SupportFooter />
      </div>
    </Modal>
  );
}
