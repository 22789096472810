import { Grid } from "ui/components/blocs";
import { H3 } from "ui/components/titles";
import { IoDocumentTextOutline } from "ui/react-icons/io5";
import { PiCookieBold } from "ui/react-icons/pi";
import Item from "./Item";

export default function Legals() {
  return (
    <div className="tile empty thin mt-05">
      <H3 className="mt-0 mb-1" size={17} icon="identification">
        Documentation légale
      </H3>

      <Grid template="1fr 1fr 1fr" space={8} className="mt-2" mediaQuery={768}>
        <Item
          icon={<IoDocumentTextOutline size={25} color="var(--color-gray15)" />}
          url="/informations/cgu"
          legend="Conditions générales d'utilisation"
        />
        <Item
          icon={<IoDocumentTextOutline size={25} color="var(--color-gray15)" />}
          url="/informations/cgv"
          legend="Conditions générales de vente"
        />
        <Item
          icon={<IoDocumentTextOutline size={25} color="var(--color-gray15)" />}
          url="/informations/annexe-cgv"
          legend="Annexe aux conditions générales de vente"
        />
        <Item
          icon={<IoDocumentTextOutline size={25} color="var(--color-gray15)" />}
          url="/informations/mentions-legales"
          legend="Mentions légales"
        />

        <Item
          icon={<PiCookieBold size={23} color="var(--color-gray15)" />}
          url="/informations/politique-des-cookies"
          legend="Politique de cookies"
        />
      </Grid>
    </div>
  );
}
