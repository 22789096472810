"use client";

import Image from "next/image";
import Link from "next/link";
import { type JSX } from "react";
import { FaUserAlt } from "../../../react-icons/fa";

export function Header() {
  type NavLinkType = {
    url: string;
    legend: string;
    icon: JSX.Element;
  };

  const NAVLINKS = [
    {
      url: "/auth/connexion",
      legend: "Connexion",
      icon: <FaUserAlt size={17} color={"var(--color-text)"} />,
    },
  ];

  return (
    <header>
      <nav>
        <Link href={"/"}>
          <div id="logo" style={{ position: "relative", width: "70px" }}>
            <Image
              alt="logo"
              src={"/logo.svg"}
              style={{
                objectFit: "contain",
              }}
              fill={true}
            />
          </div>
        </Link>
        <ul>
          {NAVLINKS.map((navlink: NavLinkType, key: number) => {
            return (
              <li key={key}>
                <Link href={navlink.url}>
                  <>{navlink.icon}</>
                  {navlink.legend}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
}
