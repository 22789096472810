import { CssVariablesType } from "../../styles/CSSVariablesTypes";

export * from "./h1/H1";
export * from "./h2/H2";
export * from "./h3/H3";
export * from "./h4/H4";

export type IconStyleType = "primary" | "primary-light" | "secondary" | "secondary-light" | "success-light" | "error-light" | "error" | "success" | "warning" | "warning-light" | "info-light" | "gray" | "info" | "gray2";

export const computeIconStyle = (style: IconStyleType | undefined): { iconColor: CssVariablesType; shapeColor: CssVariablesType; } | null => {
  switch (style) {
    case "gray":
      return { iconColor: "gray9", shapeColor: "gray1" };
    case "gray2":
      return { iconColor: "gray11", shapeColor: "gray2" };

    case "primary":
      return { iconColor: "white", shapeColor: "main" };

    case "secondary":
      return { iconColor: "white", shapeColor: "secondary" };

    case "info":
      return { iconColor: "white", shapeColor: "info" };

    case "success":
      return { iconColor: "white", shapeColor: "success" };

    case "warning":
      return { iconColor: "white", shapeColor: "warning" };

    case "error":
      return { iconColor: "white", shapeColor: "error" };

    //// LIGHTS
    case "primary-light":
      return { iconColor: "main", shapeColor: "blue-background" };

    case "secondary-light":
      return { iconColor: "secondary", shapeColor: "secondary-background" };

    case "info-light":
      return { iconColor: "info", shapeColor: "infoBackground" };

    case "error-light":
      return { iconColor: "error", shapeColor: "errorBackground" };

    case "success-light":
      return { iconColor: "success", shapeColor: "successBackground" };

    case "warning-light":
      return { iconColor: "warning", shapeColor: "warningBackground" };

    default:
      return null;

  }
};
