import { Grid } from "ui/components/blocs";
import { H3 } from "ui/components/titles";
import { FiDatabase } from "ui/react-icons/fi";
import { GrAnnounce } from "ui/react-icons/gr";
import { MdOutlineKeyboardCommandKey, MdOutlineNewReleases } from "ui/react-icons/md";
import { PiBooks } from "ui/react-icons/pi";
import Item from "./Item";

export default function Documentation() {
  return (
    <div className="tile empty thin mt-05">
      <H3 className="mt-0 mb-1" size={17} icon="documentation">
        Documentation
      </H3>

      <Grid template="1fr 1fr 1fr" space={8} className="mt-2" mediaQuery={768}>
        <Item icon={<PiBooks size={25} color="var(--color-gray15)" />} url="/informations/aide" legend="Tutos et FAQ" />
        <Item
          icon={<MdOutlineNewReleases size={22} color="var(--color-gray15)" />}
          url="/informations/versions-et-nouveautes"
          legend="Versions et nouveautés"
        />
        <Item
          icon={<FiDatabase size={22} color="var(--color-gray15)" />}
          url="/informations/ressources"
          legend="Ressources"
        />
        <Item
          icon={<GrAnnounce size={22} color="var(--color-gray15)" />}
          url="/informations/annonces"
          legend="Annonces"
        />
        <Item
          icon={<MdOutlineKeyboardCommandKey size={22} color="var(--color-gray15)" />}
          url="/informations/raccourcis-clavier"
          legend="Raccourcis claviers"
        />
      </Grid>
    </div>
  );
}
