"use client";
import { getShortCut } from "@/lib/keyboardShortcut";
import { setGlobalSearchIsOpen } from "@/redux/slices/NavigationSlice";
import { useAppDispatch } from "@/redux/store";
import classNames from "classnames";
import { KeyBoardKeyShortcut } from "ui/components/infos";
import { SearchInputText } from "ui/components/inputs";
import { IoSearch } from "ui/react-icons/io5";
import { useMediaQuery } from "utilities/hooks/useMediaQuery";
type Props = {};

const SearchCompanies = ({ }: Props) => {
  const isTablet = useMediaQuery(800);

  const dispatch = useAppDispatch();

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          dispatch(setGlobalSearchIsOpen(true));
        }}
        style={{ padding: "3px", width: isTablet ? "55px" : "fit-content", height: "55px" }}
        className={classNames("circle tile br-20 m-0 row j-center a-center relative clickable")}
      >
        {isTablet ? (
          <IoSearch size={20} color="var(--color-gray16)" />
        ) : (
          <>
            <SearchInputText
              value={""}
              setter={() => { }}
              onFocus={(e) => e.preventDefault()}
              style={{ width: "280px", fontWeight: 600 }}
              className="br-8"
              placeholder="Recherche Syndemos"
            />

            {<KeyBoardKeyShortcut item={getShortCut("search")} type="short" />}
          </>
        )}
      </div>
    </>
  );
};

export default SearchCompanies;
