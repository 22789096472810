import { setGlobalSearchIsOpen } from "@/redux/slices/NavigationSlice";
import { AppDispatch } from "@/redux/store";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

export type shortCutKeyType = {
  shortCut: string[];
  command?: (router: AppRouterInstance, dispatch: AppDispatch, state: any) => void;
  name: string;
  key: string;
  description: string;
};

export const shortcutKeys: shortCutKeyType[] = [
  {
    shortCut: ["control", "alt", "d"],
    command: (router, dispatch, state) => {
      router.push("/dashboard/dossiers");
    },
    key: "companies",
    name: "Page dossiers",
    description: "Redirige vers la page dossiers",
  },
  {
    shortCut: ["control", "k"],
    command: (router, dispatch, state) => dispatch(setGlobalSearchIsOpen(!state.navigation.globalSearchIsOpen)),
    name: "Recherche",
    key: "search",
    description: "Ouvre le menu recherche",
  },
  {
    shortCut: ["shift", "alt", "a"],
    command: (router, dispatch, state) => router.push("/dashboard"),
    name: "Page accueil",
    key: "home",
    description: "Redirige vers la page d'accueil",
  },
];

export const getShortCut = (shortCutName: shortCutKeyType["key"]): shortCutKeyType => {
  return shortcutKeys.find((shortCutKey) => shortCutKey.key === shortCutName);
};
