import { setIsOpen } from "@/redux/slices/support/TicketSlice";
import { useAppDispatch } from "@/redux/store";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { HTMLAttributes, type JSX } from "react";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  legend: string;
  url: string;
  icon: JSX.Element;
};

export default function Item({ legend, url, icon, ...props }: Props) {
  const router = useRouter();
  const dispatch = useAppDispatch();

  return (
    <button
      {...props}
      className={classNames("item clickable", props?.className)}
      onClick={(e) => {
        props?.onClick && props.onClick(e);
        dispatch(setIsOpen(false));
        router.push(url);
      }}
    >
      {icon}

      <span>{legend}</span>
    </button>
  );
}
