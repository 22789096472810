"use client";

import { shortcutKeys } from "@/lib/keyboardShortcut";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useRouter } from "next/navigation";

import { useEffect } from "react";

const KeyBoardShortcuts = () => {
  let pressedKeys = new Set<string>();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const state = useAppSelector((state) => state);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event?.key?.toLowerCase();
      pressedKeys = new Set(pressedKeys).add(key);

      // Vérifier chaque raccourci défini
      shortcutKeys.forEach(({ shortCut, command }) => {
        if (shortCut.every((key) => pressedKeys.has(key))) {
          event.preventDefault(); // Empêcher l'action par défaut (ex: Ctrl+S)

          command(router, dispatch, state); // Exécuter la commande associée
        }
      });
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      const key = event?.key?.toLowerCase();

      pressedKeys = new Set(pressedKeys);
      pressedKeys.delete(key);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [pressedKeys]); // Dépendance pour réagir aux touches pressées

  return null; // Pas besoin de rendu
};

export default KeyBoardShortcuts;
