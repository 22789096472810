import SupportBtn from "./SupportBtn";
import SupportModal from "./SupportModal";

export default function SupportContainer() {
  return (
    <>
      <SupportBtn />
      <SupportModal />
    </>
  );
}
