import classNames from "classnames";
import { Shape } from "../../../components/shapes";
import { Grid } from "../../blocs";

import { HTMLAttributes } from "react";
import { computeIconStyle, IconStyleType } from "..";
import { InfoBulle } from "../../../components/infos";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: any;
  size?: number;
  icon?: IconList | JSX.Element;
  iconColor?: CssVariablesType;
  shapeColor?: CssVariablesType;
  iconStyle?: IconStyleType;
  marginTop?: boolean;
  styleText?: any;
  withShape?: boolean;
  form?: "circle" | "square";
  infobulle?: string;
}

export function H4({
  children,
  size = 14,
  icon,
  infobulle = "",
  iconColor = "text-soft",
  shapeColor = "blue-background",
  styleText,
  iconStyle,
  className,
  withShape = true,
  form = "circle",
  ...props
}: Props) {
  let computed = computeIconStyle(iconStyle);
  const computedShapeColor = computed?.shapeColor ?? shapeColor;
  const computedIconColor = computed?.iconColor ?? iconColor;

  return (
    <Grid
      {...props}
      space={10} mediaQuery={0} template={icon ? `auto 1fr` : "1fr"} alignItems="center"
      className={classNames(!className?.includes("mb-") && !className?.includes("m-") && "mb-1", className)}
    >

      {icon && withShape && (
        <Shape form={form} color={computedShapeColor}>
          {typeof icon == "string" ? <IconButtonIcons icon={icon} size={size} color={computedIconColor} /> : <></>}
          {typeof icon !== "string" ? icon : <></>}
        </Shape>
      )}

      {icon && !withShape && (
        <>
          {typeof icon == "string" && <IconButtonIcons icon={icon} size={size * 2} color={computedIconColor} />}
          {typeof icon !== "string" && icon}
        </>
      )}

      {children && (
        <h4 className="mt-0 mb-0 row a-center" style={styleText}>
          {children}
          {infobulle?.length > 0 && <InfoBulle>{infobulle}</InfoBulle>}
        </h4>
      )}
    </Grid>
  );
}
