"use client";

import { expertFetcher } from "@/lib/http";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";

import {
  setNotificationWindowIsOpen,
  storeNotifications,
  storeNotificationsCount,
  storePreviewNotifications,
} from "@/redux/slices/notification/NotificationUserSlice";
import { setDarkmode } from "@/redux/slices/StyleSlice";
import { storeUser } from "@/redux/slices/UserSlice";

import Image from "next/image";
import { DarkModeBtn } from "ui/components/buttons";
import { Breadcrumbs } from "ui/components/navigation";
import NotificationBtn from "ui/modules/notification-header/NotificationBtn";
import HeaderUser from "../dashboard/_components_layout/header/HeaderUser";
import KeyBoardShortcuts from "../dashboard/_components_layout/KeyBoardShortcuts";
import ConnexionBtn from "./ConnexionBtn";
import SearchCompanies from "./SearchCompanies";
import SupportContainer from "./support/SupportContainer";

export default function Header() {
  const MEDIAQUERY = 920;
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const darkmode = useAppSelector((state) => state.style.darkmode);
  const windowSize = useAppSelector((state) => state.style.windowSize);
  const pathName = usePathname();
  const router = useRouter();
  // Notifications
  const notifications = useAppSelector((state) => state.notifications?.user?.notifications);
  const notificationsCount = useAppSelector((state) => state.notifications?.user?.notificationsCount);
  const notificationsPreview = useAppSelector((state) => state.notifications?.user?.previewNotifications);
  const isOpen = useAppSelector((state) => state.notifications?.user?.notificationWindowIsOpen);

  const [isConnected, setIsConnected] = useState(false);

  function updateThemePreference(theme: string) {
    if (user?.email) {
      expertFetcher.put("/user/update-theme-preference", { theme }).then((resp) => {
        if (resp?.status === 200) {
          dispatch(setDarkmode(theme));
        }
      });
    } else {
      dispatch(setDarkmode(theme));
    }
  }

  useEffect(() => {
    if (typeof window != "undefined") {
      const ttl = localStorage.getItem("token_ttl");

      if (ttl && user?.email) {
        if (Date.now() > parseInt(ttl) * 1000) {
          dispatch(storeUser(null));
        }
      }
    }
  }, []);

  useEffect(() => {

    setIsConnected(!!user?.email);

  }, [user]);

  return (
    <header>
      {!pathName.includes("dashboard") && (
        <Link aria-label="dashboard" href="/dashboard" className="column j-center header">
          <div id="logo" style={{ position: "relative", width: "70px" }}>
            <Image
              alt="logo"
              src={darkmode == false ? "/logo.svg" : "/logo_blank.svg"}
              style={{
                objectFit: "contain",
              }}
              fill={true}
            />
          </div>
        </Link>
      )}

      <Breadcrumbs />

      <div className="ml-auto mr-0 fit-content row a-center">
        {isConnected == true && (pathName.includes("/dashboard") || pathName.includes("/informations")) && (
          <SearchCompanies />
        )}

        {isConnected == true &&
          notifications &&
          (pathName.includes("/dashboard") || pathName.includes("/informations")) &&
          ((typeof window !== "undefined" && !windowSize) || windowSize?.width > MEDIAQUERY) && (
            <NotificationBtn
              user={user}
              router={router}
              fetcher={expertFetcher}
              isOpen={isOpen}
              notificationsCount={notificationsCount}
              notificationsPreview={notificationsPreview}
              storeNotificationsCount={(arg) => dispatch(storeNotificationsCount(arg))}
              storePreviewNotifications={(arg) => dispatch(storePreviewNotifications(arg))}
              storeNotifications={(arg) => dispatch(storeNotifications(arg))}
              setIsOpen={(arg) => {
                dispatch(setNotificationWindowIsOpen(arg));
              }}
              mediaQuery={false}
            />
          )}

        <>
          {isConnected == false && (
            <DarkModeBtn
              isConnected={isConnected}
              darkmode={darkmode}
              activeDarkMode={() => updateThemePreference("dark")}
              disactiveDarkMode={() => updateThemePreference("light")}
            />
          )}
        </>

        <>{isConnected == false && <ConnexionBtn />}</>
        <>{isConnected == true && <KeyBoardShortcuts />}</>

        <SupportContainer />

        {isConnected == true &&
          (!windowSize || windowSize?.width > MEDIAQUERY) &&
          (pathName.includes("/cabinet-supprime") ||
            pathName.includes("/dashboard") ||
            pathName.includes("/informations")) && <HeaderUser />}
      </div>
    </header>
  );
}
